import { Controller, useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import RichText from '@components/RichText/RichText';
import { GenericFormRadioGroupInterface } from '@sitecore/types/manual/GenericFormRadioGroup';
import { RadioButton, RadioGroup } from '@sparky';

import { FormValues, GenericFormFC, GenericFormRadioGroupProps } from '../../util';

const NAME_FALLBACK = 'radioGroupFormField';

function isGenericFormRadioGroup(field: GenericFormRadioGroupInterface) {
  return field.radioGroupFormField !== undefined;
}

const GenericFormRadioGroup: GenericFormFC<GenericFormRadioGroupProps> = ({ fields }) => {
  const { control } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormRadioGroup(fields)) {
    return null;
  }

  const { items = [] } = fields;
  const { hint, label } = fields?.radioGroupFormField?.value ?? {};
  const name = fields?.name?.value || NAME_FALLBACK;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => {
        const currentValue = typeof value === 'string' ? value : '';

        return (
          <RadioGroup
            name={name}
            label={label}
            error={errors?.[name]?.message}
            hint={<RichText html={hint}></RichText>}
            value={currentValue}
            onValueChange={onChange}>
            {items?.map(option => (
              <RadioButton value={option?.name} key={option?.id}>
                {option?.fields?.label?.value}
              </RadioButton>
            ))}
          </RadioGroup>
        );
      }}
    />
  );
};

GenericFormRadioGroup.yupValidationScheme = (fields: GenericFormRadioGroupInterface) => {
  const name = fields?.name?.value || NAME_FALLBACK;
  const { requiredMessage } = fields?.radioGroupFormField?.value ?? {};

  return { [name]: requiredMessage ? yup.string().required(requiredMessage) : yup.string() };
};

export default GenericFormRadioGroup;
